const RENZY_SIDEBARS = [
  {
    key: 1,
    label: 'Giới thiệu Rentzy',
    link: '#',
  },
  {
    key: '/member-ship',
    label: 'Gói thành viên',
    link: '/member-ship',
  },
  {
    key: 3,
    label: 'Tuyển dụng',
    link: '#',
  },
  {
    key: 4,
    label: 'Chính sách thanh toán',
    link: '#',
  },
  {
    key: 5,
    label: 'Chính sách bảo mật thông tin',
    link: '#',
  },
  {
    key: 6,
    label: 'Chính sách giải quyết khiếu nại',
    link: '#',
  },
  {
    key: 7,
    label: 'Điều khoản sử dụng',
    link: '#',
  },
  {
    key: 8,
    label: 'Bán hàng doanh nghiệp',
    link: '#',
  },
];

export default RENZY_SIDEBARS;
