import React from 'react';
import {Link} from 'gatsby';
import {Menu} from 'antd';
import {RightOutlined} from '@ant-design/icons';

import * as styles from './styles.module.css';

const SideBar = ({title, menus, activeURL}) => {
  return (
    <div>
      <div className={styles.title}>{title}</div>
      <Menu
        style={{width: 240, borderRight: 'none'}}
        selectedKeys={[activeURL]}
        defaultSelectedKeys={['1']}
        mode='inline'
      >
        {menus?.map(item => (
          <Menu.Item
            className={{
              [styles.item]: true,
              [styles.itemActive]: activeURL === item.link,
            }}
            key={item.key}
          >
            {item.link ? <Link to={item.link}>{item.label}</Link> : item.label}
            <div className={styles.icon}>{activeURL === item.link && <RightOutlined />}</div>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default SideBar;
