/* eslint-disable jsx-a11y/anchor-is-valid */
import {CheckOutlined} from '@ant-design/icons';
import React from 'react';
import {message} from 'antd';
import {toVND} from '~utils/helper';

import * as styles from './styles.module.css';

const MembershipItem = props => {
  const {name, price, packageTimeMonth, contents, color = '#8996BD'} = props;

  const info = () => {
    message.info('Vui lòng liên hệ với Rentzy để được tư vấn');
  };

  return (
    <div className={styles.item}>
      <header
        className={styles.header}
        style={{
          backgroundColor: color,
        }}
      >
        <p className={styles.price}>{`${toVND(price)}`}</p>
        <span>{`(${packageTimeMonth} tháng)`}</span>
      </header>
      <div className={styles.body}>
        <h3 className={styles.label}>{name}</h3>
        {contents
          ?.filter(x => !!x)
          .map(benefit => (
            <span className={styles.benefit}>
              <CheckOutlined
                style={{
                  color: '#EF9F84',
                }}
              />
              <span>{benefit}</span>
            </span>
          ))}
        <a className={styles.ctaButton} href='#' onClick={info}>
          Mua Gói
        </a>
      </div>
    </div>
  );
};

export default MembershipItem;
