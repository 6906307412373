import React from 'react';
import MembershipItem from './MembershipItem';
import useFetch from '../../hooks/useFetch';

import * as styles from './styles.module.css';

const Membership = () => {
  const {data = []} = useFetch(`${process.env.GATSBY_API_URL}/v1/consumer-membership-package/list`);

  return (
    <div>
      <div className={styles.list}>
        {data?.data?.map(item => (
          <MembershipItem key={item.id} {...item} />
        ))}
      </div>
      <p className={styles.des}>
        (*) Ưu đãi thuê FREE chỉ áp dụng cho khách hàng mua Gói có giá trị bằng hoặc cao hơn 40% giá trị mẫu nhập mới.
        <ul>
          <li>Thời gian sử dụng: 4 năm</li>
          <li>Thẻ thành viên không quy đổi thành tiền mặt</li>
          <li> Thẻ thành viên sẽ bị HỦY nếu khách hàng sử dụng cho mục đích kinh doanh, trục lợi khác.</li>
          <li>
            Lịch thuê gia hạn thêm cần báo trước. Rentzy sẽ áp dụng chính sách phạt trễ theo quy định chung nếu khách
            trả về trễ mà không báo trước.
          </li>
        </ul>
      </p>
    </div>
  );
};

export default Membership;
