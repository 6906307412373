import * as React from 'react';
import {Row, Col, Divider} from 'antd';
import SideBar from '~components/common/sidebars';
import AccountHeader from '~components/account/header';
import CusBreadcrumbs from '~components/breadcrumbs';
import RENZY_SIDEBARS from '~constants/sidebars';
import MemberShip from '~components/membership';

import Layout from '~components/layout';
import Seo from '~components/seo';

const MemberShipPage = props => (
  <Layout>
    <Seo title='Membership' />
    <Row justify='center' className='category-path com-bg-gray'>
      <Col xs={24} md={23} lg={21}>
        <CusBreadcrumbs items={['Gói thành viên']} />
      </Col>
    </Row>
    <Row justify='center'>
      <Col lg={21} xl={22}>
        <div id='account-page'>
          <SideBar menus={RENZY_SIDEBARS} activeURL={props.uri} title='VỀ RENTZY' />
          <div className='account-content'>
            <AccountHeader title='Gói Thành Viên' />
            <Divider />
            <MemberShip />
          </div>
        </div>
      </Col>
    </Row>
  </Layout>
);

export default MemberShipPage;
